import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import './App.css';
import Home from './Home.js';
import Projects from './Projects.js';

function App() {
  return (
      <Router>
    <div className="App">

      <div className="navigation">
        <Link to="/">Home</Link>
        <br />
        <Link to="/projects">Projects</Link>
      </div>

      <Switch>
        <Route path="/Projects">
          <Projects />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch> 
 
    </div>
  </Router>
  );
}

export default App;
